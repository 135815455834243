.container::after {
  content: "";
  clear: both;
  display: grid;
}

[class*="col-"] {
  float: left;
}
 
 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .col-xs-1 {width: 8.33%;}
  .col-xs-2 {width: 16.66%;}
  .col-xs-3 {width: 25%;}
  .col-xs-4 {width: 33.33%;}
  .col-xs-5 {width: 41.66%;}
  .col-xs-6 {width: 50%;}
  .col-xs-7 {width: 58.33%;}
  .col-xs-8 {width: 66.66%;}
  .col-xs-9 {width: 75%;}
  .col-xs-10 {width: 83.33%;}
  .col-xs-11 {width: 91.66%;}
  .col-xs-12 {width: 100%;}
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
  .col-m-1 {width: 8.33%;}
  .col-m-2 {width: 16.66%;}
  .col-m-3 {width: 25%;}
  .col-m-4 {width: 33.33%;}
  .col-m-5 {width: 41.66%;}
  .col-m-6 {width: 50%;}
  .col-m-7 {width: 58.33%;}
  .col-m-8 {width: 66.66%;}
  .col-m-9 {width: 75%;}
  .col-m-10 {width: 83.33%;}
  .col-m-11 {width: 91.66%;}
  .col-m-12 {width: 100%;}
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
  .col-lg-1 {width: 8.33%;}
  .col-lg-2 {width: 16.66%;}
  .col-lg-3 {width: 25%;}
  .col-lg-4 {width: 33.33%;}
  .col-lg-5 {width: 41.66%;}
  .col-lg-6 {width: 50%;}
  .col-lg-7 {width: 58.33%;}
  .col-lg-8 {width: 66.66%;}
  .col-lg-9 {width: 75%;}
  .col-lg-10 {width: 83.33%;}
  .col-lg-11 {width: 91.66%;}
  .col-lg-12 {width: 100%;}
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
  .col-xl-1 {width: 8.33%;}
  .col-xl-2 {width: 16.66%;}
  .col-xl-3 {width: 25%;}
  .col-xl-4 {width: 33.33%;}
  .col-xl-5 {width: 41.66%;}
  .col-xl-6 {width: 50%;}
  .col-xl-7 {width: 58.33%;}
  .col-xl-8 {width: 66.66%;}
  .col-xl-9 {width: 75%;}
  .col-xl-10 {width: 83.33%;}
  .col-xl-11 {width: 91.66%;}
  .col-xl-12 {width: 100%;}
 } 