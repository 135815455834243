
$datepicker__background-color-light: #fff !default;
$datepicker__border-color-light: #eff2f8 !default;
$datepicker__highlighted-color-light: #00e096 !default;
$datepicker__holidays-color-light: #ff3d71 !default;
$datepicker__muted-color-light: #939eb6 !default;
$datepicker__selected-color-light: #054de9 !default;
$datepicker__text-color-light: #222b45 !default;
$datepicker__header-color-light: #222b45 !default;

$datepicker__background-color-dark: #222b45 !default;
$datepicker__border-color-dark: #161c32 !default;
$datepicker__highlighted-color-dark: #0be298 !default;
$datepicker__holidays-color-dark: #ffb2bc !default;
$datepicker__muted-color-dark: #8a95ad !default;
$datepicker__selected-color-dark: #b7c4ff !default;
$datepicker__text-color-dark: #fff !default;
$datepicker__header-color-dark: #fff !default;

$datepicker__navigation-disabled-color-light: lighten(
$datepicker__muted-color-light,
10%
) !default;

$datepicker__navigation-disabled-color-dark: lighten(
$datepicker__muted-color-dark,
10%
) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Open Sans", arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-button-size: 32px !default;
$datepicker__triangle-size: 8px !default;

html[data-theme="dark"] {
    --datepicker__background-color: #{$datepicker__background-color-dark};
    --datepicker__border-color: #{$datepicker__border-color-dark};
    --datepicker__highlighted-color: #{$datepicker__highlighted-color-dark};
    --datepicker__holidays-color: #{$datepicker__holidays-color-dark};
    --datepicker__muted-color: #{$datepicker__muted-color-dark};
    --datepicker__selected-color: #{$datepicker__selected-color-dark};
    --datepicker__text-color: #{$datepicker__text-color-dark};
    --datepicker__header-color: #{$datepicker__header-color-dark};
    --datepicker__navigation-disabled-color: #{$datepicker__navigation-disabled-color-dark};
}
  
html[data-theme="light"] {
    --datepicker__background-color: #{$datepicker__background-color-light};
    --datepicker__border-color: #{$datepicker__border-color-light};
    --datepicker__highlighted-color: #{$datepicker__highlighted-color-light};
    --datepicker__holidays-color: #{$datepicker__holidays-color-light};
    --datepicker__muted-color: #{$datepicker__muted-color-light};
    --datepicker__selected-color: #{$datepicker__selected-color-light};
    --datepicker__text-color: #{$datepicker__text-color-light};
    --datepicker__header-color: #{$datepicker__header-color-light};
    --datepicker__navigation-disabled-color: #{$datepicker__navigation-disabled-color-light};
}