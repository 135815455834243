.skeleton {
  position: relative;
  z-index: 'auto';
  animation: opacity 1.2s infinite;
}

/* Skeleton animation*/
  @keyframes opacity {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
  }