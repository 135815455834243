.meter { 
  position: relative;
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.vertical {
  transform: rotate(0.5turn);
}

.progress-animated {
  animation: progressBarAnimated 1.5s infinite;
  animation-fill-mode:both; 
}

.progress {
  animation: progressBar 1.5s ease-in-out;
  animation-fill-mode:both; 
}

.progress-animated-vert {
  animation: progressBarAnimatedVert 1.5s infinite;
  animation-fill-mode:both; 
}

.progress-vert {
  animation: progressBarVert 1.5s ease-in-out;
  animation-fill-mode:both; 
}

@keyframes progressBar {
0% { width: 0; }
100% { width: 100%; }
}

@keyframes progressBarAnimated {
  0% { width: 0; }
  50% {width: 100%;}
  100% {margin-left: 100%; }
}

@keyframes progressBarVert {
  0% { height: 0; }
  100% { height: 100%; }
}
    
@keyframes progressBarAnimatedVert {
    0% { height: 0; }
    50% {height: 100%;}
    100% {margin-bottom: 100%; }
}