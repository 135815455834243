.side-drawer {
  position: fixed;
  height: 100%;
  top: 0;
  right: 100%;
  min-width: 250px;
  z-index: 200;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  transform: translateX(0);
  transition: transform 0.20s ease-out;
}

.side-drawer.open {
  transform: translateX(100%);
}

.side-drawer-icon {
  position: fixed;
  height: 100%;
  top: 0;
  right: 100%;
  z-index: 100;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  transform: translateX(0);
  transition: transform 0.2s ease-in;
}

.side-drawer-icon.open {
  transform: translateX(100%);
  transition-delay: 0.20s;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 121;
  top: 0;
  right: 0;
  backdrop-filter: blur(4px);
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 400px) {
  .side-drawer {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 400px) {
  .side-drawer {
    width: 340px;
  }
}