* {
  box-sizing: border-box;
}

.overflow-auto {
  overflow-x: auto;
  overflow-y: auto
}

.table {
  width: fit-content;
  overflow-y: auto;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
}

th,
.th {
  position: relative;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover>.resizer {
    opacity: 1;
  }
}