 .modal {
   display: block;
   position: fixed;
   z-index: 10000;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgb(0, 0, 0);
   background-color: rgba(0, 0, 0, 0.4);
 }

 .modal-content {
   height: 100%;
 }

 .dialog-content {
  position: absolute;
   /* margin: 15% auto; */
   /* overflow-y: auto; */
 }