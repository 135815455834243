%navigation-chevron {
    border-color: var(--datepicker__muted-color);
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  
    &--disabled,
    &--disabled:hover {
      border-color: var(--datepicker__navigation-disabled-color);
      cursor: default;
    }
  }
  
  %triangle-arrow-up {
    fill: var(--datepicker__background-color);
    color: var(--datepicker__background-color);
    stroke: var(--datepicker__border-color);
  }
  
  %triangle-arrow-down {
    fill: var(--datepicker__background-color);
    color: var(--datepicker__background-color);
    stroke: var(--datepicker__border-color);
  }